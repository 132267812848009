<template>
  <div class="fault">
    <!-- Main -->
    <section class="main">
      <div class="top">
        <font-awesome-icon icon="fa-regular fa-face-sad-tear" size="8x"></font-awesome-icon>
        <span class="title"></span>
        <span v-if="errorMsg"> {{ errorMsg }}</span>
        <span v-else> {{ $t('errors.default') }}</span>
      </div>
      <!-- <a href="store.html">
                <i class="fa-solid fa-house fa-2x"></i>
            </a> -->
    </section>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import { fetchErrorCode } from '@/apis/fetchData';

export default {
  name: 'Error',
  data() {
    return {
      errorMessage: null,
      // 支援i18n的 errorCode
      errorCodes: ['OR001', 'OR002', 'OR003', 'OR004', 'OR005', 'DDPW001'],
    };
  },
  created () {
    if (this.$route.query.code) {
      fetchErrorCode( this.$route.query.code )
        .then((response) => {
          console.log('fetchErrorCode done:' + JSON.stringify(response));
          const locale = this.$i18n.locale;
          let messages = response.data.messages;
          if (Object.keys(messages).includes(locale)) {
            this.errorMessage = response.data.messages[locale];
          } else {
            let key = Object.keys(messages)[0];
            this.errorMessage = response.data.messages[key];
          }
           
        })
        .catch((e) => {
          console.log('fetchSystemNotice error', e);
          this.errorMessage = null;
        });
    }
  }, 
  computed: {
    errorMsg() {
      if (this.errorMessage) {
        return this.errorMessage;
      } 
      if (
        this.$route.query.code &&
        this.errorCodes.includes(this.$route.query.code)
      ) {
        try {
          return this.$i18n.t(`errorCodes.${this.$route.query.code}`);
        } catch {
          return this.$i18n.t('errors.unknownError');
        }
      } 
      return null;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style src="@/assets/css/index.css"></style>
<!--<style src="@/assets/css/unique.css"></style>-->
